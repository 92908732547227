import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import "./App.css";
import JobDescription from "./components/JobDescription/JobDescription";
import Wizard from "./Wizard/Wizard";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer/Footer.js";
import VerificationPage from "./Auth/VerificationPage.js"; // Import the VerificationPage component
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute component
import { auth } from "./firebase.js";

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="app-container">
            <Navbar user={user} />
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<JobDescription />} />
                    <Route path="/verification" element={<VerificationPage />} /> {/* Add this route */}
                    <Route
                        path="/wizard"
                        element={<PrivateRoute element={Wizard} />}
                    />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
