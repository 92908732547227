import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./JobDescription.css";
import { getAccessToken, refreshAccessToken } from "../../Auth/tokenManager";
import { auth } from "../../firebase"; // Ensure this path is correct
import AuthModal from "../../Auth/AuthModal/AuthModal"; // Ensure this path is correct
import { onAuthStateChanged } from "firebase/auth";

const JobDescription = () => {
    const [job, setJob] = useState(null);
    const [jobExpired, setJobExpired] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [positionName, setPositionName] = useState(null);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [user, setUser] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    localStorage.setItem("id", id);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const fetchJobData = async (id, accessToken) => {
        const response = await axios.get(
            `https://saaeda--saaeda.sandbox.my.salesforce.com/services/data/v61.0/query/?q=SELECT+Id,Position_Name__c,Job_Description__c,Experience_Level__c,Job_Type__c,Skills_Competencies__c,Governorate__c,Department__c,Job_End_Date__c,Stage__c+FROM+Vacancies__c+WHERE+Id='${id}'`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        const job = response.data.records[0];
        const positionName = job.Position_Name__c;

        return { job, positionName };
    };

    useEffect(() => {
        const fetchJobDescription = async () => {
            if (id) {
                try {
                    let accessToken = await getAccessToken();

                    const { job, positionName } = await fetchJobData(
                        id,
                        accessToken
                    );
                    setJob(job);
                    setPositionName(positionName);

                    const jobEndDate = job.Job_End_Date__c;

                    // Parse the Salesforce date string to a JavaScript Date object
                    const jobEndDateObj = new Date(jobEndDate);
                    jobEndDateObj.setHours(0, 0, 0, 0);

                    // Get today's date
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    // Show expired message if the date is less than today
                    // Or stage is not "In-Progress"
                    if (
                        jobEndDateObj < today ||
                        job.Stage__c !== "In-Progress"
                    ) {
                        setJobExpired(true);
                    }
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // If token expired, refresh and retry
                        try {
                            let accessToken = await refreshAccessToken();
                            const { job, positionName } = await fetchJobData(
                                id,
                                accessToken
                            );
                            setJob(job);
                            setPositionName(positionName);
                        } catch (refreshError) {
                            setError(refreshError);
                        }
                    } else {
                        setError(error);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchJobDescription();
    }, [id, location]);

    const handleApply = () => {
        if (user) {
            navigate("/wizard", {
                state: { id: id, positionName: positionName },
            });
        } else {
            setIsAuthModalOpen(true);
        }
    };

    if (loading) {
        return (
            <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
                <div className="card-body placeholder-glow">
                    <h5 className="card-title fs-3 placeholder col-4"> </h5>
                    <br />
                    <h6 className="card-subtitle mb-2 text-muted placeholder col-2 p">
                        {" "}
                    </h6>
                    <p>
                        <span className="placeholder col-2"></span>
                        &nbsp;
                        <span className="placeholder col-2"></span>
                    </p>
                    <p>
                        <span className="placeholder col-2"></span>
                        &nbsp;
                        <span className="placeholder col-2"></span>
                    </p>
                    <p>
                        <span className="placeholder col-2"></span>
                        &nbsp;
                        <span className="placeholder col-2"></span>
                    </p>
                    <p>
                        <span className="placeholder col-2"></span>
                        &nbsp;
                        <span className="placeholder col-2"></span>
                    </p>
                    <p>
                        <span className="placeholder col-2"></span>
                        &nbsp;
                        <span className="placeholder col-2"></span>
                    </p>

                    <h2 className="placeholder col-3"> </h2>
                    <p className="placeholder col-12 p-5"></p>

                    <h2 className="placeholder col-3"> </h2>
                    <p className="placeholder col-12 p-5"></p>

                    <p className="placeholder col-9 p-3"></p>

                    <br />
                    <p
                        className="btn btn-dark disabled placeholder col-2"
                        aria-disabled="true"
                    ></p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
                <div className="card-body">
                    <div className="alert alert-danger" role="alert">
                        Error loading job description.
                    </div>
                </div>
            </div>
        );
    }

    if (!job) {
        return (
            <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
                <div className="card-body">
                    <div className="alert alert-warning" role="alert">
                        No job found.
                    </div>
                </div>
            </div>
        );
    }

    if (jobExpired) {
        return (
            <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
                <div className="card-body">
                    <div className="alert alert-warning" role="alert">
                        Unfortunately the job offer is expired or the position
                        has been filled.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
            <div className="card-body">
                <h5 className="card-title fs-3">{job.Position_Name__c}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                    {job.Job_Type__c}
                </h6>
                <p>
                    <strong>Job Title:</strong> {job.Position_Name__c}
                </p>
                <p>
                    <strong>Experience Level:</strong> {job.Experience_Level__c}
                </p>
                <p>
                    <strong>Department:</strong> {job.Department__c}
                </p>
                <p>
                    <strong>Location:</strong> {job.Governorate__c}
                </p>
                <p>
                    <strong>Job Type:</strong> {job.Job_Type__c}
                </p>
                <div
                    dangerouslySetInnerHTML={{ __html: job.Job_Description__c }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: job.Skills_Competencies__c,
                    }}
                />

                <h2>About Saaeda</h2>
                <p>
                    Saaeda Company, a prominent distributor in the medical and
                    laboratory devices trading sector, with more than 20 years
                    of history built with a mixture of boldness, innovation, and
                    visionary spirit we are exclusive distributions for Samsung
                    Ultrasound and Toray for dialysis also distributer for
                    Orgentic for laboratory, and many other reputable global
                    brands.
                </p>

                <h2>Why Saaeda?</h2>
                <ul>
                    <li>
                        You will Join a company that values integrity,
                        responsibility, and resourcefulness. Our culture is
                        built on mutual respect, humility, and continuous
                        learning.
                    </li>
                    <li>
                        We understand the importance of work-life balance and
                        strive to support our employees' personal commitments
                        and family needs.
                    </li>
                    <li>
                        Enjoy a role with a high degree of autonomy where your
                        ideas and leadership are highly valued and encouraged.
                    </li>
                    <li>
                        We offer a competitive salary and performance-based
                        incentives, ensuring that your hard work and success are
                        well-rewarded.
                    </li>
                    <li>
                        Work in a dynamic, collaborative environment where your
                        contributions make a real impact on healthcare outcomes
                        in Iraq. We foster a supportive atmosphere where work is
                        both challenging and enjoyable.
                    </li>
                </ul>

                <p>
                    Join us at Saaeda and be a part of our mission to
                    revolutionize healthcare.
                </p>

                <button
                    type="button"
                    className="btn btn-primary button px-5"
                    onClick={handleApply}
                >
                    Apply
                </button>
            </div>
            <AuthModal
                isOpen={isAuthModalOpen}
                onRequestClose={() => setIsAuthModalOpen(false)}
            />
        </div>
    );
};

export default JobDescription;
