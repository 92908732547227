// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import AuthModal from "../../Auth/AuthModal/AuthModal";
import { auth } from "../../firebase";
import "./Navbar.css";

const Navbar = ({ user }) => {
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const handleLogout = () => {
        signOut(auth);
        window.location.href = `/?id=${localStorage.getItem("id")}`;
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-logo">
                    <Link to="/">
                        <img src="public/Saaeda_Logo.png" alt="Logo" />
                    </Link>
                </div>
                <div className="navbar-login">
                    {user ? (
                        <div className="profile-section">
                            <div className="dropdown dropstart">
                                {user.photoURL ? (
                                    <img
                                        src={user.photoURL}
                                        alt="Profile"
                                        className="profile-picture dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                    />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        className="bi bi-person-circle profile-picture dropdown-toggle"
                                        viewBox="0 0 16 16"
                                        data-bs-toggle="dropdown"
                                    >
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                        <path
                                            fillRule="evenodd"
                                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                                        />
                                    </svg>
                                )}

                                <ul className="dropdown-menu">
                                    <div className="dropdown-item">
                                        {user.email}
                                    </div>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </button>
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <button
                            className="btn btn-primary button"
                            onClick={() => setIsAuthModalOpen(true)}
                        >
                            <span className="me-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    fill="currentColor"
                                    className="bi bi-box-arrow-in-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                                    />
                                </svg>
                            </span>
                            Login
                        </button>
                    )}
                </div>
            </nav>
            <AuthModal
                isOpen={isAuthModalOpen}
                onRequestClose={() => setIsAuthModalOpen(false)}
            />

            <div
                aria-live="polite"
                aria-atomic="true"
                className="bg-body-secondary position-relative bd-example-toasts rounded-3"
            >
                <div
                    className="toast-container p-3 position-fixed top-0 end-0"
                    id="toastPlacement"
                    style={{ zIndex: 9999 }}
                >
                    <div
                        id="liveToast"
                        className="toast"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="toast-header">
                            <img
                                src="/Saaeda_Logo.png"
                                className="rounded me-2 carousel-control-next-icon"
                                alt="Logo"
                            />
                            <strong className="me-auto">Notification</strong>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="toast-body">
                            Hello, world! This is a toast message.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Navbar;
