import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
} from "firebase/firestore";
import { auth, db } from "../../firebase"; // Ensure this path is correct

const AttachmentsView = ({ nextStep, prevStep, id }) => {
    const [cv, setCv] = useState(null);
    const [coverLetter, setCoverLetter] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [personalInfo, setPersonalInfo] = useState({});
    const [error, setError] = useState("");

    const allowedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    useEffect(() => {
        const fetchPersonalInfo = async () => {
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const personalInfoQuery = query(
                    collection(db, "personalInfo"),
                    where("email", "==", userEmail)
                );
                const querySnapshot = await getDocs(personalInfoQuery);

                if (!querySnapshot.empty) {
                    const personalInfoDoc = querySnapshot.docs[0];
                    setPersonalInfo({
                        id: personalInfoDoc.id,
                        ...personalInfoDoc.data(),
                    });
                }
            }
        };

        fetchPersonalInfo();
    }, []);

    const validateFile = (file) => {
        if (!allowedFileTypes.includes(file.type)) {
            setError(
                "Invalid file type. Only PDF, image, and DOC files are allowed."
            );
            return false;
        }

        if (file.size > maxFileSize) {
            setError("File size exceeds the limit of 5 MB.");
            return false;
        }

        setError("");
        return true;
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (validateFile(file)) {
            if (name === "cv") {
                setCv(file);
            } else if (name === "coverLetter") {
                setCoverLetter(file);
            }
        } else {
            if (name === "cv") {
                setCv(null);
            } else if (name === "coverLetter") {
                setCoverLetter(null);
            }
        }
    };

    const uploadFile = async (file, filePath) => {
        const storage = getStorage();
        const storageRef = ref(storage, filePath);

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };

    const handleSubmit = async () => {
        if (error) {
            showToast("Wait!", "Please fix the errors before submitting.");
            return;
        }

        if (
            !personalInfo.coverLetterURL &&
            !personalInfo.cvURL &&
            (!cv || !coverLetter)
        ) {
            showToast("Wait!", "Please upload at least one attachment.");
            return;
        }

        if (cv || coverLetter) {
            setUploading(true);

            try {
                const updates = {};

                if (cv) {
                    const cvURL = await uploadFile(cv, `cv/${id}/${cv.name}`);
                    updates.cvURL = cvURL;
                    updates.cvName = cv.name;
                }
                if (coverLetter) {
                    const coverLetterURL = await uploadFile(
                        coverLetter,
                        `coverLetters/${id}/${coverLetter.name}`
                    );
                    updates.coverLetterURL = coverLetterURL;
                    updates.coverLetterName = coverLetter.name;
                }

                const user = auth.currentUser;
                if (user) {
                    const userEmail = user.email;
                    const personalInfoQuery = query(
                        collection(db, "personalInfo"),
                        where("email", "==", userEmail)
                    );
                    const querySnapshot = await getDocs(personalInfoQuery);

                    if (!querySnapshot.empty) {
                        const personalInfoDoc = querySnapshot.docs[0];
                        const personalInfoDocRef = doc(
                            db,
                            "personalInfo",
                            personalInfoDoc.id
                        );
                        await updateDoc(personalInfoDocRef, updates);
                        nextStep();
                    } else {
                        showToast("Error!", "Personal info document not found");
                    }
                } else {
                    console.log("User not authenticated");
                }
            } catch (error) {
                showToast("Error!", "Error uploading files: " + error.message);
            } finally {
                setUploading(false);
            }
        } else {
            setUploading(false);
            nextStep();
        }
    };

    const handleDelete = async (fileFieldURL, fileFieldName) => {
        try {
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const personalInfoQuery = query(
                    collection(db, "personalInfo"),
                    where("email", "==", userEmail)
                );
                const querySnapshot = await getDocs(personalInfoQuery);

                if (!querySnapshot.empty) {
                    const personalInfoDoc = querySnapshot.docs[0];
                    const personalInfoDocRef = doc(
                        db,
                        "personalInfo",
                        personalInfoDoc.id
                    );
                    await updateDoc(personalInfoDocRef, {
                        [fileFieldURL]: null,
                        [fileFieldName]: null,
                    });

                    setPersonalInfo((prevInfo) => ({
                        ...prevInfo,
                        [fileFieldURL]: null,
                        [fileFieldName]: null,
                    }));
                }
            }
        } catch (error) {
            showToast("Error!", "Error deleting file link: " + error.message);
        }
    };

    const showToast = (header, message) => {
        const toastElement = document.getElementById("liveToast");
        const toastHeader = toastElement.querySelector(".me-auto");
        const toastBody = toastElement.querySelector(".toast-body");
        toastHeader.textContent = header;
        toastBody.textContent = message;
        const toast = new window.bootstrap.Toast(toastElement, {
            delay: 60000, // one minute
        });
        toast.show();
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h4>📎 Attachments</h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="mb-3">
                        <label htmlFor="cvInput" className="form-label">
                            Upload CV
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            id="cvInput"
                            name="cv"
                            onChange={handleFileChange}
                        />
                    </div>
                    {personalInfo.cvURL && (
                        <div className="mb-3">
                            <div className="d-flex align-items-center">
                                <a
                                    href={personalInfo.cvURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {personalInfo.cvName || "View CV"}
                                </a>
                                <span
                                    className="ms-2 text-danger cursor-pointer"
                                    onClick={() =>
                                        handleDelete("cvURL", "cvName")
                                    }
                                >
                                    ⛔
                                </span>
                            </div>
                        </div>
                    )}

                    <hr></hr>
                    <div className="mb-3">
                        <label
                            htmlFor="coverLetterInput"
                            className="form-label"
                        >
                            Upload Cover Letter
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            id="coverLetterInput"
                            name="coverLetter"
                            onChange={handleFileChange}
                        />
                    </div>
                    {personalInfo.coverLetterURL && (
                        <div className="mb-3">
                            <div className="d-flex align-items-center">
                                <a
                                    href={personalInfo.coverLetterURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {personalInfo.coverLetterName ||
                                        "View Cover Letter"}
                                </a>
                                <span
                                    className="ms-2 text-danger cursor-pointer"
                                    onClick={() =>
                                        handleDelete(
                                            "coverLetterURL",
                                            "coverLetterName"
                                        )
                                    }
                                >
                                    ⛔
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mt-2">
                    <button
                        type="button"
                        className="btn btn-success button me-1"
                        onClick={prevStep}
                        disabled={uploading}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-success button"
                        onClick={handleSubmit}
                        disabled={uploading || error}
                    >
                        {uploading ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                ></span>
                                <span role="status"> Uploading...</span>
                            </>
                        ) : (
                            "Save & Continue"
                        )}{" "}
                    </button>
                </div>
            </div>
        </div>
    );
};

AttachmentsView.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

export default AttachmentsView;
