import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const PrivateRoute = ({ element: Element, ...rest }) => {
    const [user, loading] = useAuthState(auth);

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a spinner or any loading component
    }

    if (!user || !user.emailVerified) {
        return <Navigate to="/" />; // Redirect to the home page if the user is not authenticated or not verified
    }

    return <Element {...rest} />;
};

export default PrivateRoute;
