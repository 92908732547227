import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "./StrictModeDroppable"; // Import the custom component
import { db, auth } from "../../firebase";
import { collection, addDoc, doc, getDocs, query, where, updateDoc, deleteDoc } from "firebase/firestore";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "palegreen" : "",
  ...draggableStyle,
});

const Experience = ({ nextStep, prevStep, id }) => {
  const [formData, setFormData] = useState({
    id: id,
    experienceHistory: [],
  });

  const [showForm, setShowForm] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [newEntry, setNewEntry] = useState({
    positionName: "",
    companyName: "",
    companyAddress: "",
    salary: 0,
    currency: "IQD", // Default currency
    startDate: "",
    endDate: "",
    supervisorName: "",
    supervisorNumber: "",
    leaveReason: "",
  });

  useEffect(() => {
    const fetchExperienceHistory = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userEmail = user.email;
          const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
          const querySnapshot = await getDocs(personalInfoQuery);

          if (!querySnapshot.empty) {
            const personalInfoDoc = querySnapshot.docs[0];
            const experienceHistoryRef = collection(db, "personalInfo", personalInfoDoc.id, "experienceHistory");
            const experienceSnapshot = await getDocs(experienceHistoryRef);

            const experienceHistory = experienceSnapshot.docs.map((doc) => ({
              ...doc.data(),
              expId: doc.id,
            }));

            setFormData((prevState) => ({
              ...prevState,
              experienceHistory: experienceHistory,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching experience history: ", error);
      }
    };

    fetchExperienceHistory();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(formData.experienceHistory, result.source.index, result.destination.index);

    setFormData((prevState) => ({
      ...prevState,
      experienceHistory: reorderedItems,
    }));
  };

  const handleAddOrUpdate = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
        const querySnapshot = await getDocs(personalInfoQuery);

        if (!querySnapshot.empty) {
          const personalInfoDoc = querySnapshot.docs[0];
          const personalInfoDocRef = doc(db, "personalInfo", personalInfoDoc.id);
          const experienceHistoryRef = collection(personalInfoDocRef, "experienceHistory");

          if (editItemId) {
            // Update existing document
            const experienceDocRef = doc(experienceHistoryRef, editItemId);
            await updateDoc(experienceDocRef, newEntry);
            setFormData((prevState) => ({
              ...prevState,
              experienceHistory: prevState.experienceHistory.map((item) =>
                item.expId === editItemId ? { ...item, ...newEntry } : item
              ),
            }));
          } else {
            // Add new document
            const docRef = await addDoc(experienceHistoryRef, newEntry);
            setFormData((prevState) => ({
              ...prevState,
              experienceHistory: [
                ...prevState.experienceHistory,
                {
                  ...newEntry,
                  expId: docRef.id,
                },
              ],
            }));
          }
        }
      }

      setShowForm(false);
      setEditItemId(null);
      setNewEntry({
        positionName: "",
        companyName: "",
        companyAddress: "",
        salary: 0,
        currency: "IQD",
        startDate: "",
        endDate: "",
        supervisorName: "",
        supervisorNumber: "",
        leaveReason: "",
      });
    } catch (error) {
      console.error("Error adding/updating experience history: ", error);
    }
  };

  const handleEdit = (item) => {
    setNewEntry(item);
    setEditItemId(item.expId);
    setShowForm(true);
  };

  const handleDelete = async (expId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
        const querySnapshot = await getDocs(personalInfoQuery);

        if (!querySnapshot.empty) {
          const personalInfoDoc = querySnapshot.docs[0];
          const experienceHistoryDocRef = doc(db, "personalInfo", personalInfoDoc.id, "experienceHistory", expId);

          await deleteDoc(experienceHistoryDocRef);

          setFormData((prevState) => ({
            ...prevState,
            experienceHistory: prevState.experienceHistory.filter((item) => item.expId !== expId),
          }));
        }
      }
    } catch (error) {
      console.error("Error deleting experience history: ", error);
    }
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditItemId(null);
    setNewEntry({
      positionName: "",
      companyName: "",
      companyAddress: "",
      salary: 0,
      currency: "IQD",
      startDate: "",
      endDate: "",
      supervisorName: "",
      supervisorNumber: "",
      leaveReason: "",
    });
  };



  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <h4>🏆 Experience Background</h4>
        </div>
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="btn btn-success button float-end"
            onClick={() => setShowForm(true)}
            disabled={showForm}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
            </svg>
            &nbsp; Add new Experience history
          </button>
        </div>
      </div>

      {showForm && (
        <div className={`row col-12 fade-in`}>
          <div className="col">
            <div className="card m-3 mx-auto rounded-3 shadow">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Position Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newEntry.positionName}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            positionName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newEntry.companyName}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            companyName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Company Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newEntry.companyAddress}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            companyAddress: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Salary</label>
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          value={newEntry.salary}
                          required
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              salary: e.target.value,
                            })
                          }
                        />
                        <select
                          className="form-select"
                          value={newEntry.currency}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              currency: e.target.value,
                            })
                          }
                        >
                          <option value="IQD">IQD</option>
                          <option value="USD">USD</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Start Date (mm-dd-YYYY)</label>
                      <input
                        type="date"
                        className="form-control"
                        value={newEntry.startDate}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            startDate: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">End Date (mm-dd-YYYY)</label>
                      <input
                        type="date"
                        className="form-control"
                        value={newEntry.endDate}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            endDate: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Supervisor Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newEntry.supervisorName}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            supervisorName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Supervisor Phone Number (with country code)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newEntry.supervisorNumber}
                        required
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            supervisorNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Reason for leave?</label>
                      <textarea
                        className="form-control"
                        value={newEntry.leaveReason}
                        onChange={(e) =>
                          setNewEntry({
                            ...newEntry,
                            leaveReason: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="">
                    <button type="button" className="btn btn-primary" onClick={handleAddOrUpdate}>
                      {editItemId ? "Update" : "Add"}
                    </button>
                    <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <form>
        <div className="row mt-2">
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="experienceHistory">
              {(provided, snapshot) => (
                <div id="accordionList" className="col accordion" {...provided.droppableProps} ref={provided.innerRef}>
                  {formData.experienceHistory.map((item, index) => (
                    <Draggable key={item.expId} draggableId={item.expId} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className="accordion-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.expId}`}
                            aria-expanded="false"
                            aria-controls={`collapse${item.expId}`}
                          >
                            {item.positionName}
                            &nbsp;-&nbsp;
                            <span className="text-body-secondary">{item.companyName}</span>
                          </button>
                          <div
                            id={`collapse${item.expId}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionList"
                          >
                            <div className="accordion-body">
                              <div className="row my-2">
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="positionName"
                                      value={item.positionName}
                                    />
                                    <label htmlFor="positionName">Position Name</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="companyName"
                                      value={item.companyName}
                                    />
                                    <label htmlFor="companyName">Company Name</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="companyAddress"
                                      value={item.companyAddress}
                                    />
                                    <label htmlFor="companyAddress">Company Address</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div class="input-group">
                                    <span class="input-group-text text-muted">{item.currency}</span>
                                    <div class="form-floating">
                                      <input
                                        disabled
                                        readOnly
                                        type="text"
                                        class="form-control"
                                        id="salary"
                                        placeholder="Username"
                                        value={item.salary}
                                      />
                                      <label for="salary">Salary</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="startDate"
                                      value={item.startDate}
                                    />
                                    <label htmlFor="startDate">Start Date (mm-dd-YYYY)</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="endDate"
                                      value={item.endDate}
                                    />
                                    <label htmlFor="endDate">End Date (mm-dd-YYYY)</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="supervisorName"
                                      value={item.supervisorName}
                                    />
                                    <label htmlFor="supervisorName">Supervisor Name</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="supervisorNumber"
                                      value={item.supervisorNumber}
                                    />
                                    <label htmlFor="supervisorNumber">Supervisor Phone Number</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">
                                <div className="col">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="leaveReason"
                                      value={item.leaveReason}
                                    />
                                    <label htmlFor="leaveReason">Reason for leave?</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm float-end"
                                    onClick={() => handleDelete(item.expId)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-trash3"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn me-2 btn-success button btn-sm float-end"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-pencil"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <button type="button" className="btn btn-success button me-1" onClick={prevStep}>
              Back
            </button>
            <button
              type="button"
              className={`btn ${formData.experienceHistory.length === 0 ? "btn-dark" : "btn-success"} button`}
              onClick={nextStep}
              disabled={formData.experienceHistory.length === 0}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

Experience.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Experience;
