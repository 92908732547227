import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { auth, googleAuthProvider } from "../../firebase";
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    createUserWithEmailAndPassword,
    sendEmailVerification,
} from "firebase/auth";
import "./AuthModal.css";

const AuthModal = ({ isOpen, onRequestClose }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);
    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(isOpen);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
        }
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;
        let modalInstance = null;

        if (modalElement && showModal) {
            modalInstance = new window.bootstrap.Modal(modalElement, {
                backdrop: "static",
            });
            modalInstance.show();

            modalElement.addEventListener("hidden.bs.modal", handleHidden);

            return () => {
                modalElement.removeEventListener(
                    "hidden.bs.modal",
                    handleHidden
                );
                modalInstance.dispose();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const handleHidden = () => {
        setShowModal(false);
        onRequestClose();
    };

    const handleClose = () => {
        if (modalRef.current) {
            const modalInstance = window.bootstrap.Modal.getInstance(
                modalRef.current
            );
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            if (user.emailVerified) {
                handleClose();
            } else {
                showToast(
                    "Check your email!",
                    "Please verify your email before logging in."
                );
            }
        } catch (error) {
            if (
                error.message === "Firebase: Error (auth/invalid-credential)."
            ) {
                showToast("Are you sure!", "Double check your Credentials");
            } else {
                showToast("Error!", "Error logging in: " + error.message);
            }
        }
    };

    const signInWithGoogle = () => {
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                const user = result.user;
                if (user.emailVerified) {
                    handleClose();
                } else {
                    showToast(
                        "Check your email!",
                        "Please verify your email before logging in."
                    );
                }
            })
            .catch((error) => {
                showToast("Error!", "Error logging in: " + error.message);
            });
    };

    const handleSignUp = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            await sendEmailVerification(userCredential.user);
            showToast(
                "Check your Inbox!",
                "Verification email sent. Please check your inbox."
            );
            handleClose(); // Close the modal
            setTimeout(() => {
                navigate("/verification");
            }, 2000);
        } catch (error) {
            if (
                error.message === "Firebase: Error (auth/email-already-in-use)."
            ) {
                showToast(
                    "Wait!",
                    "This email is already signed up! Did you check your inbox?"
                );
            } else {
                showToast("Error!", "Error signing up:" + error.message);
            }
        }
    };

    const showToast = (header, message) => {
        const toastElement = document.getElementById("liveToast");
        const toastHeader = toastElement.querySelector(".me-auto");
        const toastBody = toastElement.querySelector(".toast-body");
        toastHeader.textContent = header;
        toastBody.textContent = message;
        const toast = new window.bootstrap.Toast(toastElement, {
            delay: 60000, // one minute
        });
        toast.show();
    };

    return (
        <>
            {showModal && (
                <div
                    className="modal fade"
                    id="authModal"
                    tabIndex="-1"
                    aria-labelledby="authModalLabel"
                    aria-hidden="true"
                    ref={modalRef}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="authModalLabel">
                                    {isSignUp ? "Sign Up" : "Login"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control mb-2"
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    className="form-control mb-2"
                                />
                                {isSignUp ? (
                                    <button
                                        onClick={handleSignUp}
                                        className="btn btn-primary w-100 mb-2"
                                    >
                                        Sign Up
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            onClick={handleLogin}
                                            className="btn btn-success button w-100 mb-2"
                                        >
                                            Login
                                        </button>
                                        <button
                                            className="btn btn-primary w-100 mb-2"
                                            onClick={signInWithGoogle}
                                        >
                                            <img
                                                src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                                                alt="Google Icon"
                                                width={"20px"}
                                            />
                                            &nbsp;Login with Google
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <p className="w-100 text-center">
                                    {isSignUp ? (
                                        <>
                                            Already have an account?{" "}
                                            <button
                                                onClick={() =>
                                                    setIsSignUp(false)
                                                }
                                                className="btn btn-link"
                                            >
                                                Login
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            Don't have an account?{" "}
                                            <button
                                                onClick={() =>
                                                    setIsSignUp(true)
                                                }
                                                className="btn btn-link"
                                            >
                                                Sign Up
                                            </button>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AuthModal;
