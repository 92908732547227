/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db, auth } from "../../firebase"; // Ensure this path is correct
import { collection, getDocs, query, where } from "firebase/firestore";
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

const Summary = ({ prevStep, handleSubmit }) => {
    const [data, setData] = useState({
        personalInfo: {},
        educationHistory: [],
        experienceHistory: [],
    });
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    const userEmail = user.email;
                    const personalInfoQuery = query(
                        collection(db, "personalInfo"),
                        where("email", "==", userEmail)
                    );
                    const querySnapshot = await getDocs(personalInfoQuery);

                    if (!querySnapshot.empty) {
                        const personalInfoDoc = querySnapshot.docs[0];
                        const personalInfoData = personalInfoDoc.data();

                        const educationSnapshot = await getDocs(
                            collection(
                                db,
                                "personalInfo",
                                personalInfoDoc.id,
                                "educationHistory"
                            )
                        );
                        const educationData = educationSnapshot.docs.map(
                            (doc) => doc.data()
                        );

                        const experienceSnapshot = await getDocs(
                            collection(
                                db,
                                "personalInfo",
                                personalInfoDoc.id,
                                "experienceHistory"
                            )
                        );
                        const experienceData = experienceSnapshot.docs.map(
                            (doc) => doc.data()
                        );

                        setData({
                            personalInfo: personalInfoData,
                            educationHistory: educationData,
                            experienceHistory: experienceData,
                        });
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    const handleShowModal = (url) => {
        setModalContent(url);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent("");
    };

    if (loading) {
        return (
            <div className="container placeholder-glow">
                <h4>Summary</h4>
                <div className="row">
                    <div className="col px-4">
                        <h5>Personal Info</h5>
                        <ul class="list-group col shadow-sm">
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                            <li class="bg-light list-group-item">
                                <div className="row fs-3">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3"></div>
                                    <div className="col-12 col-md-3"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-success px-4">
                    <hr />
                </div>
                <div className="row">
                    <div className="col px-4">
                        <h5>Education</h5>
                        <ul class="list-group col shadow-sm  fs-3">
                            <li class="list-group-item">
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-success px-4">
                    <hr />
                </div>
                <div className="row">
                    <div className="col px-4">
                        <h5>Experience</h5>
                        <ul class="list-group col shadow-sm  fs-3">
                            <li class="list-group-item">
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                    <div className="col-12 col-md-3 placeholder"></div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-12 placeholder"></div>
                                    <div className="col-12 col-md-12 placeholder"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-success px-4">
                    <hr />
                </div>
                <div className="row">
                    <div className="col px-4 ">
                        <h5>Attachment</h5>
                        <div className="row py-2">
                            <div className="col">
                                <p
                                    className="btn btn-warning disabled placeholder col-1 me-2"
                                    aria-disabled="true"
                                ></p>

                                <p
                                    className="btn btn-warning disabled placeholder col-2"
                                    aria-disabled="true"
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 col-md-6 mt-2">
                        <button
                            type="button"
                            className="btn btn-success button me-1"
                            onClick={prevStep}
                        >
                            Back
                        </button>

                        <button
                            type="button"
                            className="btn btn-success button disabled"
                        >
                            <span
                                className="spinner-border spinner-border-sm"
                                aria-hidden="true"
                            ></span>
                            <span role="status"> Loading...</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <h4>Summary</h4>
            <div className="row">
                <div className="col px-4">
                    <h5>Personal Info</h5>
                    <ul class="list-group col shadow-sm">
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Full Name:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.fullName}
                                </div>
                                <div className="col-12 col-md-3">
                                    <strong>Email:</strong>
                                </div>
                                <div className="col-12 col-md-3">
                                    {data.personalInfo.email}
                                </div>
                            </div>
                        </li>
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Gender:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.gender}
                                </div>
                                <div className="col-12 col-md-3">
                                    <strong>Date of Birth:</strong>
                                </div>
                                <div className="col-12 col-md-3">
                                    {data.personalInfo.dob}
                                </div>
                            </div>
                        </li>
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Place of Birth:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.placeOfBirth}
                                </div>
                                <div className="col-12 col-md-3">
                                    <strong>Nationality:</strong>
                                </div>
                                <div className="col">
                                    {data.personalInfo.email}
                                </div>
                            </div>
                        </li>
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Marital Status:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.maritalStatus}
                                </div>
                                <div className="col-12 col-md-3">
                                    <strong>
                                        {data.personalInfo.maritalStatus !==
                                        "Single"
                                            ? "Number of Children:"
                                            : ""}
                                    </strong>
                                </div>
                                <div className="col-12 col-md-3">
                                    {data.personalInfo.maritalStatus !==
                                    "Single"
                                        ? data.personalInfo.numberOfChildren
                                        : ""}
                                </div>
                            </div>
                        </li>
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Religion:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.religion}
                                </div>
                                <div className="col-12 col-md-3">
                                    <strong>Current Address:</strong>
                                </div>
                                <div className="col-12 col-md-3">
                                    {data.personalInfo.currentAddress}
                                </div>
                            </div>
                        </li>
                        <li class="bg-light list-group-item">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <strong>Phone Number:</strong>
                                </div>
                                <div className="border-end no-border-end-mobile col-12 col-md-3">
                                    {data.personalInfo.phoneNumber}
                                </div>
                                <div className="col-12 col-md-3"></div>
                                <div className="col-12 col-md-3"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="text-success px-4">
                <hr />
            </div>
            <div className="row">
                <div className="col px-4">
                    <h5>Education</h5>
                    <ul class="list-group col shadow-sm">
                        {data.educationHistory.map((edu, index) => (
                            <li class="list-group-item" key={index}>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3">
                                        <strong>Institution:</strong>
                                    </div>
                                    <div className="border-end no-border-end-mobile col-12 col-md-3">
                                        {edu.name}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>Graduation Date:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {edu.graduationDate}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <strong>Certificate Level:</strong>
                                    </div>
                                    <div className="border-end no-border-end-mobile col-12 col-md-3">
                                        {edu.certificateLevel}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>Description:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {edu.description}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div class="text-success px-4">
                <hr />
            </div>
            <div className="row">
                <div className="col px-4">
                    <h5>Experience</h5>
                    <ul class="list-group col shadow-sm">
                        {data.experienceHistory.map((exp, index) => (
                            <li class="list-group-item" key={index}>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3">
                                        <strong>Position Name:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.positionName}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>Company Name:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.companyName}
                                    </div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3">
                                        <strong>Salary:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.salary}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>Company Address:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.companyAddress}
                                    </div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3">
                                        <strong>Start Date:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.startDate}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>End Date:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.endDate}
                                    </div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-3">
                                        <strong>Supervisor Name:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.supervisorName}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <strong>Supervisor Number:</strong>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        {exp.supervisorNumber}
                                    </div>
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-md-12">
                                        <strong>Reason for Leave:</strong>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        {exp.leaveReason}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="text-success px-4">
                <hr />
            </div>
            <div className="row">
                <div className="col px-4 ">
                    <h5>Attachment</h5>
                    <div className="row py-2">
                        <div className="col">
                            {data.personalInfo.cvURL && (
                                <button
                                    onClick={() =>
                                        handleShowModal(data.personalInfo.cvURL)
                                    }
                                    type="button"
                                    className="btn btn-warning"
                                >
                                    View CV
                                </button>
                            )}
                            {data.personalInfo.coverLetterURL && (
                                <button
                                    onClick={() =>
                                        handleShowModal(
                                            data.personalInfo.coverLetterURL
                                        )
                                    }
                                    type="button"
                                    className="btn btn-warning"
                                >
                                    View Cover Letter
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12 col-md-6 mt-2">
                    <button
                        type="button"
                        className="btn btn-success button me-1"
                        onClick={prevStep}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-success button"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Document Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        src={modalContent}
                        style={{ width: "100%", height: "500px" }}
                    ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

Summary.propTypes = {
    prevStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default Summary;
