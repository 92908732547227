import React, { useState, useEffect } from "react";
import PersonalInfo from "./Steps/PersonalInfo";
import Education from "./Steps/Education";
import Experience from "./Steps/Experience";
import Summary from "./Steps/Summary";
import "./Wizard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import AttachmentsView from "./Steps/AttachmentsView";

const Wizard = () => {
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        personalInfo: {},
        educationHistory: [],
        experienceHistory: [],
        attachments: [],
    });
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    const location = useLocation();
    const { id, positionName } = location.state || {};
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserVerification = () => {
            const user = auth.currentUser;

            if (user && user.emailVerified) {
                setIsVerified(true);
                fetchData(); // Fetch data only if the user is verified
            } else {
                setIsVerified(false);
                // navigate("/reqired-verify"); // Redirect to home or login page if not verified
            }
        };

        const fetchData = async () => {
            setLoading(true);
            try {
                const user = auth.currentUser;
                if (user) {
                    const userEmail = user.email;
                    const personalInfoQuery = query(
                        collection(db, "personalInfo"),
                        where("email", "==", userEmail)
                    );
                    const querySnapshot = await getDocs(personalInfoQuery);

                    if (!querySnapshot.empty) {
                        const personalInfoDoc = querySnapshot.docs[0];
                        const personalInfoData = personalInfoDoc.data();

                        const educationSnapshot = await getDocs(
                            collection(
                                db,
                                "personalInfo",
                                personalInfoDoc.id,
                                "educationHistory"
                            )
                        );
                        const educationData = educationSnapshot.docs.map(
                            (doc) => doc.data()
                        );

                        const experienceSnapshot = await getDocs(
                            collection(
                                db,
                                "personalInfo",
                                personalInfoDoc.id,
                                "experienceHistory"
                            )
                        );
                        const experienceData = experienceSnapshot.docs.map(
                            (doc) => doc.data()
                        );

                        const attachmentsSnapshot = await getDocs(
                            collection(
                                db,
                                "personalInfo",
                                personalInfoDoc.id,
                                "attachments"
                            )
                        );
                        const attachmentsData = attachmentsSnapshot.docs.map(
                            (doc) => doc.data()
                        );

                        setData({
                            personalInfo: personalInfoData,
                            educationHistory: educationData,
                            experienceHistory: experienceData,
                            attachments: attachmentsData,
                        });
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        checkUserVerification();
    }, [navigate]);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleDataChange = (newData, section) => {
        setData((prevState) => ({
            ...prevState,
            [section]: newData,
        }));
    };

    const handleSubmit = () => {
        // Final submission logic goes here
        console.log("Final data:", data);
        alert("Application submitted successfully!");
    };

    if (!isVerified) {
        return (
            <div
                className="card m-3 mx-auto rounded-4 shadow"
                style={{ width: "80rem" }}
            >
                <div className="card-body">
                    <div className="alert alert-warning" role="alert">
                        Please verify your email to access the application form.
                    </div>
                </div>
            </div>
        );
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <PersonalInfo
                        nextStep={nextStep}
                        id={id}
                        handleDataChange={(newData) =>
                            handleDataChange(newData, "personalInfo")
                        }
                    />
                );
            case 2:
                return (
                    <Education
                        nextStep={nextStep}
                        prevStep={prevStep}
                        id={id}
                        handleDataChange={(newData) =>
                            handleDataChange(newData, "educationHistory")
                        }
                    />
                );
            case 3:
                return (
                    <Experience
                        nextStep={nextStep}
                        prevStep={prevStep}
                        id={id}
                        handleDataChange={(newData) =>
                            handleDataChange(newData, "experienceHistory")
                        }
                    />
                );
            case 4:
                return (
                    <AttachmentsView
                        nextStep={nextStep}
                        prevStep={prevStep}
                        id={id}
                        handleDataChange={(newData) =>
                            handleDataChange(newData, "attachments")
                        }
                    />
                );
            case 5:
                return (
                    <Summary
                        data={data}
                        prevStep={prevStep}
                        handleSubmit={handleSubmit}
                    />
                );
            default:
                return (
                    <PersonalInfo
                        nextStep={nextStep}
                        id={id}
                        handleDataChange={(newData) =>
                            handleDataChange(newData, "personalInfo")
                        }
                    />
                );
        }
    };

    // In case of error or Id not found
    if (!id) {
        return (
            <div
                className="card m-3 mx-auto rounded-4 shadow"
                style={{ width: "80rem" }}
            >
                <div className="card-body">
                    <div className="alert alert-danger" role="alert">
                        Error loading job description.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="card m-3 mx-auto pb-3 rounded-4 shadow responsive-card-width">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h4 className="card-title fs-4">
                            POSITION YOU ARE APPLYING FOR
                        </h4>
                        <h6 className="card-subtitle mb-2 text-muted text-uppercase">
                            {positionName}
                        </h6>
                    </div>

                    <div className="col-12 col-md-6 text-end">
                        <button
                            className="btn btn-success button"
                            onClick={() =>
                                (window.location.href = `/?id=${id}`)
                            }
                        >
                            View Job Description
                        </button>
                    </div>
                </div>
                <p>
                    Click the Save & Continue button to save your work and move
                    to the next section, or click the Save button if you need to
                    log out and return at a later time.
                </p>
                <p>
                    Do not use the back button in your browser. Just click the
                    section title to review or edit.
                </p>
                <p>
                    For best security practices, please logout after you are
                    finished.
                </p>
                <p>
                    In order to minimize your time in completing the Career
                    History Form, it’s OK to enter your “best guesses” for
                    information about jobs 10 or more years ago. Please be
                    precise with more recent information.
                </p>
            </div>
            <hr />

            {loading ? (
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>📝 Personal Information</h2>
                        </div>
                    </div>
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">Email </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Full Name & Surname{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                    <div
                                        id="fullnameHelp"
                                        className="form-text"
                                    >
                                        Kindly write your First, Last, Grand
                                        Name and Surname
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">Gender</label>
                                    <select
                                        className="form-select placeholder"
                                        disabled
                                    >
                                        <option value=""></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Date of Birth (mm-dd-YYYY){" "}
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Place of Birth (City, Country){" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Marital Status{" "}
                                    </label>
                                    <select
                                        className="form-select placeholder"
                                        disabled
                                    >
                                        <option value=""></option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Number of Children{" "}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Nationality{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Religion{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        Current Address (Street, City, Country)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3 placeholder-glow">
                                    <label className="form-label">
                                        hone Number (with country code){" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control placeholder"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <button
                                    className="btn btn-success placeholder col-6"
                                    disabled
                                >
                                    &nbsp;
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                renderStep()
            )}
        </div>
    );
};

export default Wizard;
