import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const VerificationPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkEmailVerified = setInterval(async () => {
            const user = auth.currentUser;
            if (user) {
                await user.reload();
                if (user.emailVerified) {
                    clearInterval(checkEmailVerified);
                    const id = localStorage.getItem("id");
                    navigate(`/?id=${id}`);
                }
            }
        }, 1000); // Check every 3 seconds

        return () => clearInterval(checkEmailVerified);
    }, [navigate]);

    const handleReturn = () => {
        const id = localStorage.getItem("id");
        navigate(`/?id=${id}`); // Correctly use template literals
    };

    return (
        <div className="container mt-5">
            <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
                <div className="card-body">
                    <h3 className="card-title">Email Verification</h3>
                    <div className="alert alert-info" role="alert">
                        A verification email has been sent to your email
                        address. Please check your inbox and follow the
                        instructions to verify your email.
                    </div>
                    <button className="btn btn-primary" onClick={handleReturn}>
                        Return to Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VerificationPage;
