import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "./StrictModeDroppable"; // Import the custom component
import { db, auth } from "../../firebase";
import {
    collection,
    addDoc,
    doc,
    getDocs,
    query,
    where,
    updateDoc,
    deleteDoc,
} from "firebase/firestore";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "palegreen" : "",
    ...draggableStyle,
});

const Education = ({ nextStep, prevStep, id }) => {
    const [formData, setFormData] = useState({
        id: id,
        educationHistory: [],
    });

    useEffect(() => {
        const fetchEducationHistory = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    const userEmail = user.email;
                    const personalInfoQuery = query(
                        collection(db, "personalInfo"),
                        where("email", "==", userEmail)
                    );
                    const querySnapshot = await getDocs(personalInfoQuery);

                    if (!querySnapshot.empty) {
                        const personalInfoDoc = querySnapshot.docs[0];
                        const educationHistoryRef = collection(
                            db,
                            "personalInfo",
                            personalInfoDoc.id,
                            "educationHistory"
                        );
                        const educationSnapshot = await getDocs(
                            educationHistoryRef
                        );

                        const educationHistory = educationSnapshot.docs.map(
                            (doc) => ({
                                ...doc.data(),
                                eduId: doc.id,
                            })
                        );

                        setFormData((prevState) => ({
                            ...prevState,
                            educationHistory: educationHistory,
                        }));
                    }
                }
            } catch (error) {
                console.error("Error fetching education history: ", error);
            }
        };

        fetchEducationHistory();
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            formData.educationHistory,
            result.source.index,
            result.destination.index
        );

        setFormData((prevState) => ({
            ...prevState,
            educationHistory: reorderedItems,
        }));
    };

    const [showForm, setShowForm] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [newEntry, setNewEntry] = useState({
        name: "",
        graduationDate: "",
        certificateLevel: "",
        description: "",
    });

    const handleAddOrUpdate = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const personalInfoQuery = query(
                    collection(db, "personalInfo"),
                    where("email", "==", userEmail)
                );
                const querySnapshot = await getDocs(personalInfoQuery);

                if (!querySnapshot.empty) {
                    const personalInfoDoc = querySnapshot.docs[0];
                    const personalInfoDocRef = doc(
                        db,
                        "personalInfo",
                        personalInfoDoc.id
                    );
                    const educationHistoryRef = collection(
                        personalInfoDocRef,
                        "educationHistory"
                    );

                    if (editItemId) {
                        // Update existing document
                        const educationDocRef = doc(
                            educationHistoryRef,
                            editItemId
                        );
                        await updateDoc(educationDocRef, newEntry);
                        setFormData((prevState) => ({
                            ...prevState,
                            educationHistory: prevState.educationHistory.map(
                                (item) =>
                                    item.eduId === editItemId
                                        ? { ...item, ...newEntry }
                                        : item
                            ),
                        }));
                    } else {
                        // Add new document
                        const docRef = await addDoc(
                            educationHistoryRef,
                            newEntry
                        );
                        setFormData((prevState) => ({
                            ...prevState,
                            educationHistory: [
                                ...prevState.educationHistory,
                                {
                                    ...newEntry,
                                    eduId: docRef.id,
                                },
                            ],
                        }));
                    }
                }
            }

            setShowForm(false);
            setEditItemId(null);
            setNewEntry({
                name: "",
                graduationDate: "",
                certificateLevel: "",
                description: "",
            });
        } catch (error) {
            console.error("Error adding/updating education history: ", error);
        }
    };

    const handleEdit = (item) => {
        setNewEntry(item);
        setEditItemId(item.eduId);
        setShowForm(true);
    };

    const handleDelete = async (eduId) => {
        try {
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const personalInfoQuery = query(
                    collection(db, "personalInfo"),
                    where("email", "==", userEmail)
                );
                const querySnapshot = await getDocs(personalInfoQuery);

                if (!querySnapshot.empty) {
                    const personalInfoDoc = querySnapshot.docs[0];
                    const educationHistoryDocRef = doc(
                        db,
                        "personalInfo",
                        personalInfoDoc.id,
                        "educationHistory",
                        eduId
                    );

                    await deleteDoc(educationHistoryDocRef);

                    setFormData((prevState) => ({
                        ...prevState,
                        educationHistory: prevState.educationHistory.filter(
                            (item) => item.eduId !== eduId
                        ),
                    }));
                }
            }
        } catch (error) {
            console.error("Error deleting education history: ", error);
        }
    };

    const handleCancel = () => {
        setShowForm(false);
        setEditItemId(null);
        setNewEntry({
            name: "",
            graduationDate: "",
            certificateLevel: "",
            description: "",
        });
    };

    const getCertificateIcon = (level) => {
        switch (level) {
            case "Lower than high school":
                return "🤷‍♂️";
            case "High school":
                return "🎒";
            case "Diploma":
                return "💪";
            case "Bachelor's":
                return "👨🏻‍🎓";
            case "Master":
                return "🥷🏻";
            case "PhD":
                return "🧙";
            default:
                return "";
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h4>🎓 Education Background</h4>
                </div>
                <div className="col-12 col-md-6">
                    <button
                        type="button"
                        className="btn btn-success button float-end"
                        onClick={() => setShowForm(true)}
                        disabled={showForm}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                        </svg>
                        &nbsp; Add new Education history
                    </button>
                </div>
            </div>

            {showForm && (
                <div className={`row fade-in justify-content-md-center`}>
                    <div className="col">
                        <div className="card m-3 mx-auto rounded-3 shadow">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={newEntry.name}
                                                required
                                                onChange={(e) =>
                                                    setNewEntry({
                                                        ...newEntry,
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Graduation Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={newEntry.graduationDate}
                                                required
                                                onChange={(e) =>
                                                    setNewEntry({
                                                        ...newEntry,
                                                        graduationDate:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Certificate Level
                                            </label>
                                            <select
                                                className="form-control"
                                                required
                                                value={
                                                    newEntry.certificateLevel
                                                }
                                                onChange={(e) =>
                                                    setNewEntry({
                                                        ...newEntry,
                                                        certificateLevel:
                                                            e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="" disabled>
                                                    Select...
                                                </option>
                                                <option value="Lower than high school">
                                                    Lower than high school 🤷‍♂️
                                                </option>

                                                <option value="High school">
                                                    High school 🎒
                                                </option>
                                                <option value="Diploma">
                                                    Diploma 💪
                                                </option>

                                                <option value="Bachelor's">
                                                    Bachelor's 👨🏻‍🎓
                                                </option>
                                                <option value="Master">
                                                    Master 🥷🏻
                                                </option>
                                                <option value="PhD">
                                                    PhD 🧙
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Description
                                            </label>
                                            <textarea
                                                className="form-control"
                                                value={newEntry.description}
                                                onChange={(e) =>
                                                    setNewEntry({
                                                        ...newEntry,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddOrUpdate}
                                        >
                                            {editItemId ? "Update" : "Add"}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary ms-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <form>
                <div className="row">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId="educationHistory">
                            {(provided, snapshot) => (
                                <div
                                    className="col"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {formData.educationHistory.map(
                                        (item, index) => (
                                            <Draggable
                                                key={item.eduId}
                                                draggableId={item.eduId}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="card m-3 mx-auto rounded-3 shadow"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                    >
                                                        <div className="card-header fw-medium">
                                                            {item.name}
                                                            &nbsp;-&nbsp;
                                                            <span className="text-body-secondary">
                                                                {
                                                                    item.certificateLevel
                                                                }{" "}
                                                                {getCertificateIcon(
                                                                    item.certificateLevel
                                                                )}
                                                            </span>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm float-end"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item.eduId
                                                                    )
                                                                }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    fill="currentColor"
                                                                    className="bi bi-trash3"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn me-2 btn-success button btn-sm float-end"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    fill="currentColor"
                                                                    className="bi bi-pencil"
                                                                    viewBox="0 0 16 16"
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="card-subtitle mb-2 ">
                                                                {
                                                                    item.description
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="card-footer text-body-secondary fw-medium">
                                                            Graduation
                                                            date:&nbsp;
                                                            {
                                                                item.graduationDate
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mt-2">
                        <button
                            type="button"
                            className="btn btn-success button me-1"
                            onClick={prevStep}
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            className={`btn ${
                                formData.educationHistory.length === 0
                                    ? "btn-dark"
                                    : "btn-success"
                            } button`}
                            onClick={nextStep}
                            disabled={formData.educationHistory.length === 0}
                        >
                            Save & Continue
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

Education.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

export default Education;
