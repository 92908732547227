import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="contact-right">
                    <p>
                        <strong>Phone:</strong> +123-456-7890
                    </p>
                    <p>
                        <strong>Address:</strong> 123 Main Street, Baghdad, Iraq
                    </p>
                    <p>
                        <strong>Email:</strong> contact@saaeda.com
                    </p>
                    <p>
                        <strong>Website:</strong> www.saaeda.com
                    </p>
                </div>
                <div className="contact-center">
                    <p>
                        <strong>Phone:</strong> +123-456-7890
                    </p>
                    <p>
                        <strong>Address:</strong> 123 Main Street, Baghdad, Iraq
                    </p>
                    <p>
                        <strong>Email:</strong> contact@saaeda.com
                    </p>
                    <p>
                        <strong>Website:</strong> www.saaeda.com
                    </p>
                </div>
                <div className="contact-left">
                    <p>
                        <strong>Phone:</strong> +123-456-7890
                    </p>
                    <p>
                        <strong>Address:</strong> 123 Main Street, Baghdad, Iraq
                    </p>
                    <p>
                        <strong>Email:</strong> contact@saaeda.com
                    </p>
                    <p>
                        <strong>Website:</strong> www.saaeda.com
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
