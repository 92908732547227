import React, { useState, useEffect } from "react";
import {
    collection,
    addDoc,
    query,
    getDocs,
    where,
    doc,
    updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase.js"; // Ensure this path is correct
import { onAuthStateChanged } from "firebase/auth";
import InputMask from "react-input-mask";

const PersonalInfo = ({ nextStep, id }) => {
    const [formData, setFormData] = useState({
        VacanciesId: id,
        email: "",
        fullName: "",
        gender: "",
        dob: "",
        placeOfBirth: "",
        nationality: "",
        maritalStatus: "",
        numberOfChildren: "",
        religion: "",
        currentAddress: "",
        phoneNumber: "",
    });

    const [errors, setErrors] = useState({});
    const [docId, setDocId] = useState(null);

    const today = new Date().toISOString().split("T")[0];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userEmail = user.email;
                setFormData((prevData) => ({
                    ...prevData,
                    email: userEmail,
                }));

                const userQuery = query(
                    collection(db, "personalInfo"),
                    where("email", "==", userEmail)
                );
                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    setDocId(userDoc.id);
                    const userData = userDoc.data();
                    setFormData((prevData) => ({
                        ...prevData,
                        ...userData,
                    }));
                }
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const forms = document.querySelectorAll(".needs-validation");
        Array.from(forms).forEach((form) => {
            form.addEventListener(
                "submit",
                (event) => {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                    }
                    form.classList.add("was-validated");
                },
                false
            );
        });
    }, []);

    const validate = () => {
        let tempErrors = {};
        let valid = true;

        if (
            !formData.fullName ||
            formData.fullName.length < 13 ||
            formData.fullName.length > 50
        ) {
            tempErrors.fullName =
                "Full Name must be between 13 and 50 characters.";
            valid = false;
        }

        if (!formData.gender) {
            tempErrors.gender = "Gender can't be blank.";
            valid = false;
        }

        if (!formData.dob || new Date(formData.dob) > new Date()) {
            tempErrors.dob = "Date of Birth can't be in the future.";
            valid = false;
        }

        if (
            !formData.placeOfBirth ||
            formData.placeOfBirth.length < 3 ||
            formData.placeOfBirth.length > 50
        ) {
            tempErrors.placeOfBirth =
                "Place of Birth must be between 3 and 50 characters.";
            valid = false;
        }

        if (!formData.maritalStatus) {
            tempErrors.maritalStatus = "Marital Status can't be blank.";
            valid = false;
        }

        if (
            formData.maritalStatus !== "Single" &&
            (formData.numberOfChildren === "" ||
                formData.numberOfChildren < 0 ||
                formData.numberOfChildren > 15)
        ) {
            tempErrors.numberOfChildren =
                "Number of Children must be between 0 and 15.";
            valid = false;
        }

        if (
            !formData.nationality ||
            formData.nationality.length < 3 ||
            formData.nationality.length > 20
        ) {
            tempErrors.nationality =
                "Nationality must be between 3 and 20 characters.";
            valid = false;
        }

        if (
            !formData.religion ||
            formData.religion.length < 3 ||
            formData.religion.length > 15
        ) {
            tempErrors.religion =
                "Religion must be between 3 and 15 characters.";
            valid = false;
        }

        if (
            !formData.currentAddress ||
            formData.currentAddress.length < 10 ||
            formData.currentAddress.length > 55
        ) {
            tempErrors.currentAddress =
                "Current Address must be between 10 and 55 characters.";
            valid = false;
        }

        if (!formData.phoneNumber || formData.phoneNumber.length !== 20) {
            tempErrors.phoneNumber =
                "Phone Number can't be blank and must be as this example (+123) 750 123 456 78.";
            valid = false;
        }

        setErrors(tempErrors);

        return valid;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                if (formData.maritalStatus === "Single") {
                    formData.numberOfChildren = 0;
                }
                if (docId) {
                    // Update existing document
                    const docRef = doc(db, "personalInfo", docId);
                    await updateDoc(docRef, formData);
                } else {
                    await addDoc(collection(db, "personalInfo"), formData);
                }
                nextStep();
            } catch (e) {
                console.error("Error updating document: ", e);
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h4>📝 Personal Information</h4>
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
            >
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="fullnameInput"
                                className="form-label"
                            >
                                Full Name & Surname
                            </label>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.fullName ? "is-invalid" : ""
                                }`}
                                id="fullnameInput"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                aria-describedby="fullnameHelp"
                                required
                                minLength={13}
                                maxLength={50}
                            />
                            <div id="fullnameHelp" className="form-text">
                                Kindly write your First, Last, Grand Name and
                                Surname
                            </div>
                            <div className="invalid-feedback">
                                {errors.fullName}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label htmlFor="genderInput" className="form-label">
                                Gender
                            </label>
                            <select
                                className={`form-select ${
                                    errors.gender ? "is-invalid" : ""
                                }`}
                                id="genderInput"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select...
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <div className="invalid-feedback">
                                {errors.gender}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label htmlFor="dobInput" className="form-label">
                                Date of Birth (mm-dd-YYYY)
                            </label>
                            <input
                                type="date"
                                name="dob"
                                className={`form-control ${
                                    errors.dob ? "is-invalid" : ""
                                }`}
                                id="dobInput"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                                max={today}
                            />
                            <div className="invalid-feedback">{errors.dob}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label htmlFor="pobInput" className="form-label">
                                Place of Birth (City, Country)
                            </label>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.placeOfBirth ? "is-invalid" : ""
                                }`}
                                id="pobInput"
                                name="placeOfBirth"
                                value={formData.placeOfBirth}
                                onChange={handleChange}
                                required
                                minLength={3}
                                maxLength={50}
                            />
                            <div className="invalid-feedback">
                                {errors.placeOfBirth}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="maritalStatusInput"
                                className="form-label"
                            >
                                Marital Status
                            </label>
                            <select
                                className={`form-select ${
                                    errors.maritalStatus ? "is-invalid" : ""
                                }`}
                                id="maritalStatusInput"
                                name="maritalStatus"
                                value={formData.maritalStatus}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select...
                                </option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widowed">Widowed</option>
                            </select>
                            <div className="invalid-feedback">
                                {errors.maritalStatus}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="numberOfChildrenInput"
                                className="form-label"
                                hidden={formData.maritalStatus === "Single"}
                            >
                                Number of Children
                            </label>
                            <input
                                type="number"
                                className={`form-control ${
                                    errors.numberOfChildren ? "is-invalid" : ""
                                }`}
                                id="numberOfChildrenInput"
                                name="numberOfChildren"
                                value={
                                    formData.maritalStatus === "Single"
                                        ? 0
                                        : formData.numberOfChildren
                                }
                                onChange={handleChange}
                                hidden={formData.maritalStatus === "Single"}
                                required={formData.maritalStatus !== "Single"}
                                min={0}
                                max={15}
                            />
                            <div className="invalid-feedback">
                                {errors.numberOfChildren}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="nationalityInput"
                                className="form-label"
                            >
                                Nationality
                            </label>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.nationality ? "is-invalid" : ""
                                }`}
                                id="nationalityInput"
                                name="nationality"
                                value={formData.nationality}
                                onChange={handleChange}
                                required
                                minLength={3}
                                maxLength={20}
                            />
                            <div className="invalid-feedback">
                                {errors.nationality}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="religionInput"
                                className="form-label"
                            >
                                Religion
                            </label>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.religion ? "is-invalid" : ""
                                }`}
                                id="religionInput"
                                name="religion"
                                value={formData.religion}
                                onChange={handleChange}
                                required
                                minLength={3}
                                maxLength={15}
                            />
                            <div className="invalid-feedback">
                                {errors.religion}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="currentAddressInput"
                                className="form-label"
                            >
                                Current Address (Street, City, Country)
                            </label>
                            <input
                                type="text"
                                className={`form-control ${
                                    errors.currentAddress ? "is-invalid" : ""
                                }`}
                                id="currentAddressInput"
                                name="currentAddress"
                                value={formData.currentAddress}
                                onChange={handleChange}
                                required
                                minLength={10}
                                maxLength={55}
                            />
                            <div className="invalid-feedback">
                                {errors.currentAddress}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            <label
                                htmlFor="phoneNumberInput"
                                className="form-label"
                            >
                                Phone Number
                            </label>
                            <InputMask
                                mask="(+999) 999 999 99 99"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            >
                                {() => (
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.phoneNumber
                                                ? "is-invalid"
                                                : ""
                                        }`}
                                        id="phoneNumberInput"
                                        name="phoneNumber"
                                        required
                                        minLength={50}
                                    />
                                )}
                            </InputMask>
                            <div className="invalid-feedback">
                                {errors.phoneNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div>
                        <button
                            type="submit"
                            className="btn btn-success button"
                        >
                            Save & Continue
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PersonalInfo;
