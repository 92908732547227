import axios from "axios";

export const getAccessToken = async () => {
  try {
    const response = await axios.post("https://saaeda.server.istisharatdatabase.com/getAccessToken");
    return response.data.accessToken;
  } catch (error) {
    console.error("Error getting access token:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const refreshAccessToken = async () => {
  try {
    const response = await axios.post("https://saaeda.server.istisharatdatabase.com/refreshAccessToken");
    return response.data.accessToken;
  } catch (error) {
    console.error("Error refreshing access token:", error.response ? error.response.data : error.message);
    throw error;
  }
};
